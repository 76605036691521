.main-container{
  min-height: calc(100vh - 163px);
}
.card-profile{
  width: 480px; 
  margin: auto;
  padding: 10px;
}
.card-verse{
  border-color: #e8e8e8 !important;
}
.dashboard{
  margin-top:"60px" !important;
}
.ql-toolbar{
  border: 0 !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

::-webkit-scrollbar {display:none;}
@media screen and (max-width: 480px) {
  .img-canvas{
    width: 100%;
  }
}
@media only screen and (max-width: 998px) {
  span.hide{
    display: none;
  }
}